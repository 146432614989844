import './App.css';
import { useMediaQuery } from 'react-responsive'
import MobileViewXS from './MobileViewXS.js';
import MobileView from './MobileView.js';
import DesktopView from './DesktopView.js';
import TabletView from './TabletView.js';


function App() {

  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1080px)",
  });
  const isTablet =  useMediaQuery({
    query: "(min-device-width: 768px)",
  });
   const isMobile =  useMediaQuery({
    query: "(min-device-width: 321px)",
  });

  const introText = "Graphic Designer and Educator in Santa Fe, New Mexico";
  const dwdText = "Graphic design, copywriting, and creative direction, specializing in visual communications for public health and cultural organizations.";
  const bramLettersText = "Comprehensive comics lettering, design, and production for independent publishers.";
  const bcText = "Secretary of the board and teacher for a nonprofit supporting independent comics creators and comics education.";
  const bramFencingText = "Assistant coach and instructor at Northern New Mexico’s fencing school.";
  const bramCorpsText = "Advisory Committee member for organization advocating for the value of design.";
  const polaroidText = "Photography using vintage and modern Polaroid cameras posted to Pixelfed."; 
  const mastodonText = "Only active social media account."; 
  const somedText = "Occasionally check these and other social media sites, but don’t post or engage."; 



  if (isLaptop) {
    return <DesktopView introText={introText}
                        dwdText={dwdText}
                        bramLettersText={bramLettersText}
                        bcText={bcText}
                        bramFencingText={bramFencingText}
                        bramCorpsText={bramCorpsText}
                        polaroidText={polaroidText} 
                        mastodonText={mastodonText} 
                        somedText={somedText}/>

} else if (isTablet) {
  return <TabletView introText={introText}
                        dwdText={dwdText}
                        bramLettersText={bramLettersText}
                        bcText={bcText}
                        bramFencingText={bramFencingText}
                        bramCorpsText={bramCorpsText}
                        polaroidText={polaroidText} 
                        mastodonText={mastodonText}
                        somedText={somedText}/>


} else if (isMobile) {
  return <MobileView introText={introText}
                        dwdText={dwdText}
                        bramLettersText={bramLettersText}
                        bcText={bcText}
                        bramFencingText={bramFencingText}
                        bramCorpsText={bramCorpsText}
                        polaroidText={polaroidText} 
                        mastodonText={mastodonText}
                        somedText={somedText}/>


} else {
  return <MobileViewXS introText={introText}
                        dwdText={dwdText}
                        bramLettersText={bramLettersText}
                        bcText={bcText}
                        bramFencingText={bramFencingText}
                        bramCorpsText={bramCorpsText}
                        polaroidText={polaroidText} 
                        mastodonText={mastodonText}
                        somedText={somedText}/>

  }
}


export default App;
