import './FormattedLink.css'
const FormattedLink = ({text, link, displayValue=null, customFontSize="24px"}) => {
  return(
    <div style={{
      fontFamily: "ff-scala",
      fontSize: customFontSize,
      color: "#7EA143",
      paddingTop: "20px",
      display: displayValue ? displayValue : "block"
    }}>
       <a href={link}><b>{text}</b></a>
    </div>
  )
}

export default FormattedLink;