const Footer = () => {
  return(
    <div style={{
      paddingTop: "80px",
      maxWidth: "500px"
    }}>
      <div style={{
        width: "900px"
      }}>
        <div style={{
            fontFamily: "ff-scala",
            fontSize: "18px",
            color: "#3b4539",
            paddingRight: "14px",
            display: "inline-block"
          }}>
          Bram Meehan
        </div>
        <div style={{
            fontFamily: "ff-scala",
            fontSize: "18px",
            color: "#3b4539",
            display: "inline-block"
        }}>
        |
        </div>
        <div style={{
            fontFamily: "ff-scala",
            fontSize: "18px",
            color: "#7EA143",
            padding: "14px",
            display: "inline-block"
        }}>
          <a href="mailto:bram@brammeehan.com">bram@brammeehan.com</a>
        </div>
        <div style={{
            fontFamily: "ff-scala",
            fontSize: "18px",
            color: "#3b4539",
            display: "inline-block"
        }}>
        |
        </div>
        <div style={{
            fontFamily: "ff-scala",
            fontSize: "18px",
            color: "#3b4539",
            padding: "14px",
            display: "inline-block"
          }}>
          +1-505-982-2300
        </div>
        <div style={{
            fontFamily: "ff-scala",
            fontSize: "18px",
            color: "#3b4539",
            display: "inline-block"
        }}>
        |
        </div>
        <div style={{
            fontFamily: "ff-scala",
            fontSize: "18px",
            color: "#3b4539",
            paddingLeft: "14px",
            display: "inline-block"
          }}>
          Santa Fe, New Mexico, USA
        </div>
      </div>
    </div>
  )
}

export default Footer;