import FormattedLink from './FormattedLink.js';
import DesktopText from './TabletText.js';

import Footer from './MobileFooter.js';

const TabletView = (props) => {
  return (
    <div>
      <link rel="stylesheet" href="https://use.typekit.net/hau2shn.css"></link>
      <div style={{
        backgroundColor: "#77a632",
        minHeight: "180px",
        display: "flex"
      }}>
        <div style={{ flexBasis: "15%"}} />
        <div>
          <div style={{
            fontFamily: "ff-scala",
            fontSize: "56px",
            color: "white",
            paddingTop: "30px"
          }}>
            Bram Meehan
          </div>
          <div style={{
            maxWidth: "500px",
            fontFamily: "ff-scala",
            fontSize: "31px",
            color: "#3b4539",
            overflowWrap: 'break-word',
          }}>
            {props.introText}
          </div>
        </div>
      </div>
      <div style={{
        backgroundColor: "white",
        display: "flex"
      }}>
        <div style={{ flexBasis: "15%"}} />
        <div>
          <FormattedLink text="Design, Writing, + Direction" link="https://www.designwritingdirection.com/" />
          <DesktopText text={props.dwdText} />
          <FormattedLink text="Bram Letters" link="https://www.bramletters.com/" />
          <DesktopText text={props.bramLettersText} />
          <FormattedLink text="7000 BC" link="https://www.7000bc.org/" />
          <DesktopText text={props.bcText} />
          <FormattedLink text="New Mexico Fencing Foundation" link="https://www.nmfencing.org/" />
          <DesktopText text={props.bramFencingText} />
          <FormattedLink text="Design Corps" link="https://design-corps.org/" />
          <DesktopText text={props.bramCorpsText} />
          <FormattedLink text="Polaroid Photography" link="https://pixelfed.social/BramMeehanPhoto" displayValue="inline-block" />
          <DesktopText text={props.polaroidText} />
          <FormattedLink text="Mastodon" link="https://ohai.social/@BramMeehan" rel="me" displayValue="inline-block" padding="30px" />
          <DesktopText text={props.mastodonText} />
          <FormattedLink text="Facebook" link="https://facebook.com/bram.meehan/" displayValue="inline-block" padding="14px" />
          <div style={{
              fontFamily: "ff-scala",
              fontSize: "22px",
              color: "#3b4539",
              paddingLeft: "14px",
              paddingRight: "14px",
              paddingTop: "20px",
              display: "inline"
            }}>
            |
          </div>
          <FormattedLink text="Instagram" link="https://www.instagram.com/brammeehan/" displayValue="inline-block" padding="14px" />
          <div style={{
            fontFamily: "ff-scala",
            fontSize: "22px",
            color: "#3b4539",
            paddingLeft: "14px",
            paddingRight: "14px",
            paddingTop: "20px",
            display: "inline"
            }}>
            |
          </div>

          <FormattedLink text="LinkedIn" link="https://www.linkedin.com/in/brammeehan/" displayValue="inline-block" padding="14px" />
          <DesktopText text={props.somedText} />
          <div style={{ 
              borderTop: "2px solid #7EA143 ", 
              marginLeft: 0, 
              marginRight: 20, 
              marginTop: 20,
             }} >
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default TabletView;
