const MobileFooter = () => {
  return(
    <div style={{
      display: "block",
      paddingTop: "30px"
    }}>
      <div style={{
          fontFamily: "ff-scala",
          fontSize: "18px",
          color: "#3b4539"
        }}>
        Bram Meehan
      </div>
      <div style={{
          fontFamily: "ff-scala",
          fontSize: "18px",
          color: "#7EA143"
      }}>
        <a href="mailto:bram@brammeehan.com">bram@brammeehan.com</a>
      </div>
      <div style={{
          fontFamily: "ff-scala",
          fontSize: "18px",
          color: "#3b4539"
        }}>
        +1-505-982-2300
      </div>
      <div style={{
          fontFamily: "ff-scala",
          fontSize: "18px",
          color: "#3b4539",
          paddingBottom: "20px"
        }}>
        Santa Fe, New Mexico, USA
      </div>
    </div>
  )
}

export default MobileFooter;