const TabletText = ({text}) => {
  return(
    <div style={{
      overflowWrap: 'break-word',
      maxWidth: "600px",
      fontFamily: "ff-scala",
      fontSize: "22px",
      color: "black",
      paddingRight: "10px",
      paddingLeft: "0px"
    }}>
      {text}
    </div>
  )
}

export default TabletText;