import FormattedLink from './FormattedLink.js';
import DesktopText from './DesktopText.js';

import profile from './bram_profile.png';
import Footer from './Footer.js';

const DesktopView = (props) => {
  return (
    <div>
      <link rel="stylesheet" href="https://use.typekit.net/hau2shn.css"></link>
      <div style={{
        backgroundColor: "#77a632",
        minHeight: "230px"
      }} />
      <div style={{
        backgroundColor: "white",
        minHeight: "900px",
      }} />
      <div style={{
        position: "absolute",
        top: "250px",
        right: "50%",
        width: "500px"
      }}>
        <FormattedLink text="Design, Writing, + Direction" link="https://www.designwritingdirection.com/" />
        <DesktopText text={props.dwdText} />
        <FormattedLink text="Bram Letters" link="https://www.bramletters.com/" />
        <DesktopText text={props.bramLettersText} />
        <FormattedLink text="7000 BC" link="https://www.7000bc.org/" />
        <DesktopText text={props.bcText} />
        <FormattedLink text="New Mexico Fencing Foundation" link="https://www.nmfencing.org/" />
        <DesktopText text={props.bramFencingText} />
        <FormattedLink text="Design Corps" link="https://design-corps.org/" />
        <DesktopText text={props.bramCorpsText} />
        <FormattedLink text="Polaroid Photography" link="https://pixelfed.social/BramMeehanPhoto" displayValue="inline-block" />
        <DesktopText text={props.polaroidText} />
        <FormattedLink text="Mastodon" link="https://ohai.social/@BramMeehan" rel="me" displayValue="inline-block"/>
        <DesktopText text={props.mastodonText} />
        <FormattedLink text="Facebook" link="https://facebook.com/bram.meehan/" displayValue="inline-block" padding="30px" />
        <div style={{
            fontFamily: "ff-scala",
            fontSize: "24px",              color: "#3b4539",
            paddingLeft: "14px",
            paddingRight: "14px",
            paddingTop: "20px",
            display: "inline"
          }}>
          |
          </div>
        <FormattedLink text="Instagram" link="https://www.instagram.com/brammeehan/" displayValue="inline-block" padding="14px" /> 
          <div style={{
            fontFamily: "ff-scala",
            fontSize: "24px",              color: "#3b4539",
            paddingLeft: "14px",
            paddingRight: "14px",
            paddingTop: "20px",
            display: "inline"
          }}>
          |
        </div>
        <FormattedLink text="LinkedIn" link="https://www.linkedin.com/in/brammeehan/" displayValue="inline-block" padding="14px" />
        <DesktopText text={props.somedText} />
        <Footer />

      </div>
      <div style={{
        position: "absolute",
        top: "0px",
        right: "50%"
      }}>
        <div style={{
          fontFamily: "ff-scala",
          fontSize: "64px",
          color: "white",
          paddingTop: "30px"
        }}>
          Bram Meehan
        </div>
        <div style={{
          maxWidth: "500px",
          fontFamily: "ff-scala",
          fontSize: "32px",
          color: "#3b4539",
          overflowWrap: 'break-word',
        }}>
          {props.introText}
        </div>
      </div>
      <img src={profile} img alt="Black-and-white illustration of Bram Meehan smiling with arms crossed by Colin Murdoch." title="Black-and-white illustration of Bram Meehan smiling with arms crossed by Colin Murdoch."
        style={{
          position: "absolute",
          height: "730px",
          top: "20px",
          left: "60%"
        }}>
      </img>
    </div>
  )
}

export default DesktopView;